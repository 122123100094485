import * as React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';

import { cn } from 'app/utils/tw';
import { ButtonProps, buttonVariants } from './button';
import { RiCloseLine } from '@remixicon/react';
import { VariantProps, cva } from 'class-variance-authority';

const dialogVariants = cva(
  'fixed left-[50%] top-[50%] z-50 grid w-full translate-x-[-50%] translate-y-[-50%] border bg-background shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg',
  {
    variants: {
      size: {
        default: 'max-w-2xl',
        sm: 'max-w-md',
        lg: 'max-w-4xl',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
);

const Dialog = ({
  children,
  ...props
}: React.ComponentProps<typeof DialogPrimitive.Dialog>) => (
  <DialogPrimitive.Dialog {...props}>{children}</DialogPrimitive.Dialog>
);
Dialog.displayName = 'Dialog';

Dialog.Portal = DialogPrimitive.Portal;

Dialog.Trigger = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <DialogPrimitive.Trigger
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      >
        {props.children}
      </DialogPrimitive.Trigger>
    );
  },
);
Dialog.Trigger.displayName = 'DrawerTrigger';

Dialog.Content = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    className?: string;
    size?: VariantProps<typeof dialogVariants>['size'];
  }
>(({ size, className, children, ...props }, ref) => (
  <DialogPrimitive.DialogPortal>
    <Dialog.Overlay />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(dialogVariants({ size, className }))}
      {...props}
    >
      {children}
    </DialogPrimitive.Content>
  </DialogPrimitive.DialogPortal>
));
Dialog.Content.displayName = 'DialogContent';

const DialogHeader: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    className?: string;
  }
> = ({ className, ...props }) => (
  <div
    className={cn(
      'flex flex-row items-center justify-between border-b border-b-homy-gray-lighter px-6 py-3',
      className,
    )}
    {...props}
  />
);
DialogHeader.displayName = 'DialogHeader';
Dialog.Header = DialogHeader;

const DialogBody: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    className?: string;
  }
> = ({ className, ...props }) => (
  <div
    className={cn(
      'max-h-[calc(100vh-11rem)] min-h-0 overflow-auto px-6 py-8',
      className,
    )}
    {...props}
  />
);
DialogBody.displayName = 'DialogBody';
Dialog.Body = DialogBody;

Dialog.Title = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title> & {
    className?: string;
  }
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn('h3 p-0 text-base', className)}
    {...props}
  />
));
Dialog.Title.displayName = 'DialogTitle';

Dialog.Close = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <DialogPrimitive.Close
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      >
        {props.children}
      </DialogPrimitive.Close>
    );
  },
);
Dialog.Close.displayName = 'DialogClose';

const CloseIcon: React.FC<React.ComponentProps<typeof Dialog.Close>> = ({
  ...props
}) => (
  <Dialog.Close
    className="bg-transparent p-0 text-homy-gray-darker hover:bg-transparent hover:shadow-none"
    size="icon"
    {...props}
  >
    <RiCloseLine size={24} />
  </Dialog.Close>
);
Dialog.CloseIcon = CloseIcon;

Dialog.Description = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description> & {
    className?: string;
  }
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn('text-sm text-muted-foreground', className)}
    {...props}
  />
));
Dialog.Description.displayName = 'DialogDescription';

Dialog.Overlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentProps<typeof DialogPrimitive.Overlay> & { className?: string }
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      'fixed inset-0 z-50 bg-black/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className,
    )}
    {...props}
  />
));
Dialog.Overlay.displayName = 'DialogOverlay';

const Footer: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    className?: string;
  }
> = ({ className, ...props }) => (
  <div
    className={cn(
      'flex flex-col-reverse gap-4 border-t border-t-homy-gray-lighter px-6 py-4 sm:flex-row sm:justify-end',
      className,
    )}
    {...props}
  />
);
Footer.displayName = 'DialogFooter';
Dialog.Footer = Footer;

export { Dialog };
